import React, { FC, useEffect, useState } from "react"
import Styles from "../styles/help.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import smoothscroll from 'smoothscroll-polyfill';
import Image from "../components/image";
import clsx from "clsx"
import { BACK_BUTTON_STR } from "../constant/const";
import { DownArrowIcon } from "../icons/DownArrowIcon";


if ( ( typeof window !== 'undefined' ) && ( typeof document !== 'undefined' ) ) {
    smoothscroll.polyfill();
}

const helpList = [
    {
        category: "投稿",
        QAList: [
            {
                question: "既に投稿済みの投稿の編集や削除はどこでできますか？",
                answer: "投稿画面下の方に、投稿日時が書いてあります。" +
                    "\nその段の、一番右、下向きの矢印を押すとメニューが現れますのでそこから編集や削除ができます。",
                image: [ "help/update_delete_button-min.jpg" ]
            },
            {
                question: "メンションはどうやるんですか？",
                answer: "投稿やコメント時に文章を打つ際、@をつけると、チームメンバーの名前が候補に出てきます。" +
                    "\nチームメンバーを選択すると、その名前が打ち込まれますので、メンションしたことになります。" +
                    "\n変換で@を出した場合には候補が出ない可能性があります。" +
                    "\nメンションされた人にはプッシュ通知が届きます",
                image: [
                    "help/post_mention-min.jpg",
                ]
            },
            {
                question: "リプライ(返信)やコメント削除はどうやるんですか？",
                answer: "コメントを長押しすると、メニューが現れます。" +
                    "\nそこから、そのコメントに対するリプライ(返信)や削除を行えます"

            },
            {
                question: "コメントの実名/匿名ってなんですか？",
                answer: "通常、投稿内のコメントはアカウント名が表示されています。" +
                    "\nコメントを匿名設定にすることで、自分のアカウント名を非表示にできます。" +
                    "\nただしこの設定はコメントごとではなく投稿ごと、つまり匿名設定にした投稿内でのすべてのあなたのコメントが匿名になります。",
                image: [ "help/anonymous_comment-min.jpg" ]
            },
        ]
    },
    {
        category: "動画比較",
        QAList: [
            {
                question: "どこで動画比較ができますか？",
                answer: "投稿の詳細画面で動画比較ができます。"
                    + "\n比較したい動画の片方は、投稿かコメントでアップロードしてある必要があります。",
                image: [ "help/video_comparison_button-min.jpg" ]
            },
            {
                question: "どうやったら動画比較ができますか？",
                answer: "投稿詳細画面の動画を一つ以上選択して、右上の比較ボタンを押してください。"
                    + "\n選択は動画をタッチするとできます。",
                image: [ "help/video_comparison_button-min.jpg" ]
            },
            {
                question: "自分のスマホの動画も比較に使用できますか？",
                answer: "できます。" +
                    "\n投稿の詳細画面で動画を一つだけ選択してから右上の選択ボタンを押すと、画面下部にスマホの動画の選択ボタンが現れます。",
                image: [ "help/show_device_video_button-min.jpg" ]
            },
            {
                question: "異なる投稿の動画同士は比べられますか？",
                answer: "比べられます。" +
                    "\n動画プレイヤーの一番右のボタンを押すと、「他の投稿での比較に使用」というメニューが出てきます。" +
                    "\nそのメニューをタッチすると、画面上部の動画リストの一番左にその動画が追加されます。" +
                    "\nこの一番左の動画はどの投稿をでも共通になっているので、比較したい動画のある投稿で、これを選択し、動画比較にしようしてください。",
                image: [ "help/video_menu_button-min.jpg" ]
            },
        ]
    },
    {
        category: "動画キャプチャへの描き込み",
        QAList: [
            {
                question: "どうやったら動画のキャプチャに描き込めますか？",
                answer: "動画の右上の鉛筆のマークをタッチしてください。"
                    + "\nその動画の現在の再生位置の画像に書き込むことができます。"
                    + "\nコメントへの添付もできます。",
                image: [ "help/draw_button-min.jpg", "help/draw_mode-min.jpg" ]
            },
        ]
    },
    {
        category: "動画の移動や拡大、ダウンロード",
        QAList: [
            {
                question: "どうやって動画を拡大/縮小しますか？",
                answer: "二本の指で動画をタッチして、画面から指を離さずに指の間の距離を変えてみてください。"
                    + "\n二本の指の間の距離に応じて動画が拡大/縮小されます。",
                image: [ "help/video_zoom-min.jpg" ]
            },
            {
                question: "どうやって動画を移動しますか？",
                answer: "上記の方法で動画を拡大すると、一本指のタッチで動画を動かすことができます。"
                    + "\n動画を元の大きさに戻すと、動画の位置も元に戻ります。",
                image: [ "help/video_move-min.jpg" ]
            },
            {
                question: "どうやって動画をダウンロードしますか？",
                answer: "動画プレイヤーの一番右のボタンを押すと、「動画をダウンロード」というメニューが出てきます。"
                    + "\nそのメニューをタッチすると、動画がダウンロードされます。",
                image: [ "help/video_menu_button-min.jpg" ]
            },
        ]
    },
    {
        category: "チーム",
        QAList: [
            {
                question: "チームに参加したいです",
                answer: "まず、マイページの右上の歯車をタッチしメニューを表示してください。"
                    + "\nその中にチームという項目があるので、それをタッチするとチーム参加(作成)画面に移ります。",
                image: [ "help/team_button-min.jpg" ]
            },
            {
                question: "チームを作成したいです",
                answer: "まず、マイページの右上の歯車をタッチしメニューを表示してください。"
                    + "\nその中にチームという項目があるので、それをタッチするとチーム作成(参加)画面に移ります。",
                image: [ "help/team_button-min.jpg" ]
            },
        ]
    },
    {
        category: "タイムライン",
        QAList: [
            {
                question: "タイムラインの「フォロー中のスポーツ」に投稿がありません",
                answer: "まず、マイページの右上の歯車をタッチしメニューを表示してください。" +
                    "\nその中の登録情報変更を開いていただくと、下の方に「タイムラインに表示するスポーツ」を選択する箇所があるかと思います。" +
                    "\nお好きなスポーツを選んで、右上の保存ボタンを押すと「フォロー中のスポーツ」のタイムラインに多ユーザーの投稿が現れます。",
                image: [ "help/setting_button-min.jpg", "help/follow_sports_button-min.jpg" ]
            },
        ]
    },
    {
        category: "登録情報",
        QAList: [
            {
                question: "どの項目が他人に公開されますか",
                answer: "公開されるのは、プロフィール写真とお名前、プロフィール文のみです。",
                image: [ "help/public_profile-min.jpg" ]
            },
            {
                question: "「自分のスポーツ」ってなんですか？",
                answer: "自分の投稿に紐づくスポーツです。" +
                    "\n公開範囲を「一般公開」にすると、そのスポーツをフォローしている人のタイムラインに表示されます。",
                image: [ "help/my_sports-min.jpg" ]
            },
        ]
    },
    {
        category: "タグ",
        QAList: [
            {
                question: "タグとMyタグってどう違いますか？",
                answer: "タグはチームで共有のタグ、Myタグは自分にしか見えない(使えない)タグです。" +
                    "\nどちらのタグも、どんな投稿にもつけられます。" +
                    "\nあとで検索しやすいようにドンドンつけましょう。"
            },
            {
                question: "使いやすいタグの名前の付け方は？",
                answer: "投稿の本文に含まれて「いない」ような言葉が好ましいです。" +
                    "\n例えばひねり技の投稿では、「ひねり」という言葉はほぼ確実に本文に入っています。" +
                    "\nその場合は「ひねり」でワード検索すれば探し出すことができますので、わざわざ「ひねり」というタグをつける意味がありません。" +
                    "\nもっと抽象的な技や種目のカテゴリ名が良いでしょう。" +
                    "\n例 : 「宙返り」、「トランポリン 」等",
                image: [ "help/word_search-min.jpg" ]
            },
        ]
    }
]

const scrollToContent = ( categoryID: string ) => {
    const category = document.getElementById( categoryID )
    if ( !category ) return
    const featureY = category.getBoundingClientRect().top
    const offsetTop = window.pageYOffset
    const buffer = 60
    const top = featureY + offsetTop - buffer
    window.scrollTo( {
        top,
        behavior: "smooth"
    } );
}


const HelpPage = () => {

    const headerOption = {
        headerTitle: "ヘルプ",
        leftIcon: BACK_BUTTON_STR,
    }

    useEffect( () => {
        document.documentElement.scrollTop = 0
    }, [] )

    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="Help" />
                <div className={ Styles.container }>
                    <div className={ Styles.category_container }>
                        <h2>カテゴリ<span>(タッチでジャンプできます)</span></h2>
                        <ul>
                            { helpList.map( item =>
                                <li
                                    key={ item.category }
                                    onClick={ () => scrollToContent( item.category ) }>
                                    ・ { item.category }
                                </li>
                            ) }
                        </ul>
                    </div>
                    <ul>
                        { helpList.map( item =>
                            <li key={ item.category }>
                                <section id={ item.category }>
                                    <h2>{ item.category }</h2>
                                    { item.QAList.map( QA =>
                                        <QABox
                                            key={ QA.question }
                                            question={ QA.question }
                                            answer={ QA.answer }
                                            imageURLList={ QA.image } />
                                    ) }
                                </section>
                            </li> ) }
                    </ul>
                </div>
            </>
        </Layout>
    )
}

type Props = {
    question: string,
    answer: string,
    imageURLList?: string[]
}

const QABox: FC<Props> = ( {
    question,
    answer,
    imageURLList
} ) => {

    const [ answerOpen, setAnswerOpen ] = useState( false )

    return (
        <div className={ Styles.QA_wrapper }>
            <p className={ Styles.question } onClick={ () => setAnswerOpen( !answerOpen ) } >
                <span className={ Styles.question_text }>Q. { question }</span>
                <span className={ Styles.icon_wrapper }>
                    <DownArrowIcon className={ clsx( Styles.icon, answerOpen && Styles.open_icon ) } />
                </span>
            </p>
            <div className={ clsx( Styles.answer, answerOpen && Styles.open_answer ) }>
                <p>
                    { answer }
                </p>
                <div>
                    { ( imageURLList && imageURLList.length )
                        &&
                        imageURLList.map( ( imageURL: string ) =>
                            <Image
                                key={ imageURL }
                                filename={ imageURL }
                                style={ { height: "300px", width: "300px", margin: 'auto', marginBottom: 16 } } /> )
                    }
                </div>
            </div>

        </div>
    );
}

export default HelpPage
